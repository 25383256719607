@font-face {
    font-family: "Noto Sans Korean";
    src: url("../assets/fonts/NotoSansKR-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans Korean";
    src: url("../assets/fonts/NotoSansKR-Bold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans Korean";
    src: url("../assets/fonts/NotoSansKR-SemiBold.woff2") format("woff2");
    font-weight: 700; /* ExtraBold */
    font-style: normal;
    font-display: swap;
}
